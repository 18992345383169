import { Component, OnInit } from '@angular/core';
import { VCard, VCardEncoding } from 'ngx-vcard';

@Component({
  selector: 'app-dinv-card',
  templateUrl: './dinv-card.component.html',
  styleUrls: ['./dinv-card.component.scss']
})
export class DinvCardComponent implements OnInit {
  

  public vCardEncoding: typeof VCardEncoding;
  public vCard: VCard = {};

  constructor() {
    this.vCardEncoding = VCardEncoding;
    this.vCard = { 
      name: { firstNames: 'DI NGUYEN', lastNames: 'Mr.' },
      organization: 'BB TECHNOLOGY',
      telephone: ['0775668118'],
      email: ['di.nguyen@bb-tech.vn'],
      title: 'Chief Delivery Officer',
      url: 'https://bb-tech.vn'
    };
  }

  ngOnInit(): void {
  }

  
  private vCardCreator() {
    // const vCard = vCardsJS();

    // vCard.firstName = 'Mr. DI NGUYEN';
    // vCard.organization = 'BB TECHNOLOGY';
    // // vCard.photo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG');
    // vCard.cellPhone = '0775668118';
    // vCard.email = 'di.nguyen@bb-tech.vn';
    // vCard.title = 'Chief Delivery Officer';
    // vCard.url = 'https://bb-tech.vn';

    // return vCard.getFormattedString();
  }

}
